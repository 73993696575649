
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  ref,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { ApiBase, ApiOrderTracking } from "@/core/api";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import moment from "moment";
import { ProductItem } from "@/core/directive/interface/order";
import {
  formatDate,
  formatDateTime,
  setModuleBCN,
} from "@/core/directive/function/common";

export default defineComponent({
  name: "mb-order-routing-order-tracking-information",
  components: {},
  setup() {
    const { t } = useI18n();

    const route = useRoute();
    const router = useRouter();

    const { showServerErrorMsg } = mixin();

    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const cancelButton = ref<HTMLElement | null>(null);

    const formData = ref({
      items: [] as any[],
      sku_id: route.query.sku_id || "",
      order_number_encry: route.query.order_number_encry || "",
      ordered_qty: route.query.ordered_qty || 0,
      product: {} as ProductItem,
    });

    const currentInstance: any = getCurrentInstance();

    const getShowInfo = async () => {
      const { data } = await ApiOrderTracking.getOrderTrackingInfo({
        id: route.params.id,
        data_id: route.params.item_id,
      });
      if (data.code == 0) {
        formData.value.items = data.data;
      } else {
        showServerErrorMsg(data);
      }
    };

    const getProductData = async () => {
      if (!formData.value.sku_id) return;
      const { data } = await ApiBase.getProductData({
        sku_id: formData.value.sku_id,
      });
      if (data.code == 0) {
        formData.value.product = data.data;
      }
    };

    const getFromInfo = async () => {
      loading.value = true;
      Promise.all([getShowInfo(), getProductData()])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const backToList = () => {
      router.push("/mb-order-routing/order-tracking");
    };

    const isBigThanCurrentDate = computed(() => {
      return (date) => {
        return (
          moment(date).utcOffset(2).format("YYYY-MM-DD").valueOf() >
          moment().format("YYYY-MM-DD").valueOf()
        );
      };
    });

    onMounted(() => {
      setModuleBCN(t, route, router);
      getFromInfo();
    });

    return {
      t,
      formatDate,
      formatDateTime,
      loading,
      formData,
      formRef,
      cancelButton,
      submitButton,
      getShowInfo,
      getFromInfo,
      backToList,
      isBigThanCurrentDate,
    };
  },
});
